/******************************************************************


	------------------------
	-- TABLE OF CONTENTS --
	------------------------
	
	--  1. Basic
	--  2. Preloader
    --  3. Hero
    --  4. Hero --> Front Content
    --  5. Hero --> Background Content
    --  6. Popup Subscribe
 
 
 ******************************************************************/




/** 1. Basic
*******************************************************************/

html
{
    font-size: 10px !important;
}

body
{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-style: normal;

    overflow: hidden;

    color: #ffffff;
    background: #000;

    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a
{
    font-family: 'Roboto', sans-serif;
    font-weight: normal;

    color: rgb(255,184,0);
    /* background: rgb(255,184,0);
    background: linear-gradient(180deg, rgba(255,184,0,1) 0%, rgba(232,198,0,1) 60%); */
/* -webkit-background-clip: text;
-webkit-text-fill-color: transparent; */
}

h1
{
    font-size: 6rem;
}

h2
{
    font-size: 5rem;
}

h3
{
    font-size: 4rem;
}

h4
{
    font-size: 3rem;
}

h5
{
    font-size: 2rem;
}

h6
{
    font-size: 1.8rem;
}

p
{
    font-size: 1.4rem;
}

a,
a:hover,
a:focus
{
    cursor: pointer;
    -webkit-transition: all 300ms ease;
         -o-transition: all 300ms ease;
            transition: all 300ms ease;
    text-decoration: none;
}

a:hover
{
    color: #fff;
}

@media (max-width:700px)
{
    html
    {
        font-size: 8px !important;
    }
}

@media (max-width:500px)
{
    html
    {
        font-size: 6px !important;
    }
}

@media (max-width:350px)
{
    html
    {
        font-size: 5px !important;
    }
}

@media (orientation:landscape) and (max-height:420px)
{
    html
    {
        font-size: 8px !important;
    }
}


/** 3. Hero
*******************************************************************/

.hero
{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

/** 4. Hero --> Front Content 
*******************************************************************/

.hero .front-content
{
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

.hero .front-content .container-mid
{
    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;

    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    text-align: center;
}

.hero .front-content .container-mid img.logo
{
    display: block;

    margin: 0 auto;
}

.hero .front-content .container-mid h1
{
    font-weight: bold;
    line-height: 1.1em;

    margin-top: .5em;
    margin-bottom: .46em;

    letter-spacing: .025em;
}

.hero .front-content .container-mid p.subline
{
    font-size: 3rem;
    line-height: 1.4em;

    max-width: 18em;
    margin-right: auto;
    margin-bottom: 1.3em;
    margin-left: auto;

    letter-spacing: .025em;
}

.hero .front-content .container-mid .open-popup
{
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 2.6em;

    position: relative;

    display: inline-block;
    overflow: hidden;

    padding: 0 2.6em;

    cursor: pointer;
    -webkit-transition: all .1s linear;
         -o-transition: all .1s linear;
            transition: all .1s linear;
    letter-spacing: .025em;

    color: #fff;
    border: 3px solid #fff;
    border-radius: 4em;
    background: none;
}

.hero .front-content .container-mid .open-popup:hover
{
    color: #111;
    background: #fff;
}

.hero .front-content .footer
{
    position: absolute;
    bottom: 10px;

    width: 100%;

    text-align: center;
}

.hero .front-content .footer p
{
    font-size: 1.3rem;

    letter-spacing: .025em;

    opacity: .85;
}

.hero .front-content .footer p a
{
    font-weight: bold;
}

@media (orientation:landscape) and (max-height:420px)
{
    .hero .front-content .footer
    {
        display: none;
    }
}



/** 5. Hero --> Background Content 
*******************************************************************/

.hero .background-content
{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

.hero .background-content.parallax-on
{
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;

    width: 110%;
    height: 110%;

    -webkit-transform: translate3d(-50%, -50%, 0px) !important;
            transform: translate3d(-50%, -50%, 0px) !important;
}

.hero .background-content .background-overlay
{
    position: absolute;
    z-index: 50;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: .7;
    /* background: #ffae00f4; */
}

.hero .background-content .background-img
{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: url(../img/background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

